<template>
  <div id="areyousure">
    <v-dialog v-model="areYouSurePopUp" max-width="600px">
      <v-card>
        <h2 class="text-center">Are You Sure You Want To {{this.type}}</h2>
        <v-card-actions>
          <v-btn rounded color="primary" @click="closepopup">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            class="white"
            :loading="loading1"
            @click="confrimed"
            :disabled="loading1 "
            color="primary"
          >
            Yes
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-refresh</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "AreYouSure",
  data() {
    return {
      loading1: false,
      areYouSurePopUp: false,
      type: null
    };
  },
  async mounted() {},
  methods: {
    openpopup(from, Id) {
      this.type = from;
      this.Id = Id;
      this.areYouSurePopUp = true;
    },
    closepopup() {
      this.areYouSurePopUp = false;
      const message = "canceled";
      window.parent.postMessage(message, "*");
    },
    confrimed() {
      this.areYouSurePopUp = false;
      const message = this.type + " Confrimed" + "?Id=" + this.Id;
      window.parent.postMessage(message, "*");
      this.loading1 = true;
    }
  },
  destroyed: {
    close() {
      const message = "canceled";
      window.parent.postMessage(message, "*");
    }
  }
};
</script>
<style scoped>
</style>