<template>
  <v-app>
    <PageHeader />

    <v-main>
      <loading v-if="loading2 == true" />
      <are-you-sure ref="areyousure" />
      <v-card class="mainBody " ref="myaccount" v-if="loading2 == false" color="#E5E5E5">
        <div>
          <h1 class="text-center">My Account</h1>
        </div>
        <br />
        <h3>Available Mail Credits {{emailCredits}}</h3>
        <div>
          <v-btn depressed @click="buyCredits" color="#ffffff00">
            <v-icon large>mdi-message-plus</v-icon>buy credits
          </v-btn>

          <buy-credits v-bind:usercards="usercards" ref="buycredits" />
          <add-camper v-bind:id="id" />
        </div>
        <v-card shaped raised class="accountinfo rounded-corner center pl-5 pr-5" color="white" max-width="1050px">
          <h2 class="text-center pa-0">Personal Information</h2>
          <v-form class="pt-2" ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  prepend-icon="mdi-account"
                  v-model="firstname"
                  label="First Name"
                  :rules="[v => !!v || 'Item is required']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                    prepend-icon="mdi-account"
                  v-model="lastname"
                  label="Last Name"
                  :rules="[v => !!v || 'Item is required']"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  prepend-icon="mdi-email"
                  v-model="email"
                  label="Email"
                  :rules="[v => !!v || 'E-mail is required', v => (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v)) || 'E-mail must be valid']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>

          <v-form ref="form2" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                    prepend-icon="mdi-lock"
                  v-on:keyup.enter="login"
                  :type="showPassword ? 'text' : 'password'"
                  label="Current Password"
                  v-model="currentpassword"
                  :rules="[v => !!v || 'Password is required', v => (v && v.length >= 6) || 'Password must have more than 6 characters']"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye '"
                  @click:append="showPassword = !showPassword"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                    prepend-icon="mdi-lock"
                  v-on:keyup.enter="login"
                  :type="showNewPassword ? 'text' : 'password'"
                  label="New Password"
                  v-model="newpassword"
                  :append-icon="showNewPassword ? 'mdi-eye-off' : 'mdi-eye '"
                  @click:append="showNewPassword = !showNewPassword"
                  :rules="[v => (v && v.length >= 6) || 'Password must have more than 6 characters']"
                />
              </v-col>
            </v-row>
            <v-row class="pl-3">
<v-icon>
  mdi-email-newsletter
</v-icon>
              <v-switch

                v-model="newsletter"
                class="mt-0 pl-2"
                color="green lighten-2"
                hide-details
                label="Newsletter"
              ></v-switch>
            </v-row>
            <div class="text-center pt-8 pb-2" >
              <h2 class="danger-alert">{{this.error}}</h2>
              <v-btn
                rounded
                :loading="loading1"
                @click="save"
                :disabled="loading1 ||!valid"
                color="primary"
                class="text-center"
              >
                Save Changes
                <v-icon light>mdi-content-save</v-icon>
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-refresh</v-icon>
                  </span>
                </template>
              </v-btn>
            </div>
          </v-form>
        </v-card>
        <br />
        <h2 class="text-center">Campers</h2>
        <v-btn depressed @click="addCameper" color="#ffffff00">
          <v-icon large>mdi-account-multiple-plus</v-icon>Add Camper
        </v-btn>
        <add-camper v-bind:id="id" ref="addcamper" />
        <br />
        <template>
          <v-row>
            <v-col v-for="camper in usercampers" :key="camper.Id" cols="12" sm="6" md="4" lg="4">
              <v-card shaped class="cards rounded-corner">
                <v-card-title
                  class="subheading font-weight-bold"
                >{{ camper.firstName + ' ' + camper.lastName }}</v-card-title>
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>Branch Name:</v-list-item-content>
                    <v-list-item-content class="align-end">{{ camper.branchName }}</v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content v-if="camper.bunkName">Bunk Name:</v-list-item-content>
                    <v-list-item-content class="align-end">{{ camper.bunkName }}</v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-icon @click="areyousure('Delete Camper',camper.camperId)">mdi-delete</v-icon>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </template>
        <h2 class="text-center">Payment Methods</h2>
        <v-btn class=" mb-12" depressed @click="addCard" color="#ffffff00">
          <v-icon>mdi-credit-card-plus-outline</v-icon>Add New Credit Card
        </v-btn>
        <add-card ref="addcard" />
        <template>
          <v-row>
            <v-col v-for="card in usercards" :key="card.Id" cols="12" sm="6" md="4" lg="3">
              <v-card shaped class="cards cc rounded-corner">
                <v-card-title class="subheading font-weight-bold">{{ card.CardType }}</v-card-title>
                <v-divider></v-divider>
                <v-list>
                  <v-list-item>
                    <v-list-item-content class="center-text">{{ card.MaskedCardNumber }}</v-list-item-content>
                  </v-list-item>

                  <v-divider></v-divider>
                  <v-list-item>
                    <v-icon  @click="areyousure('Delete Card',card.Id)">mdi-delete</v-icon>                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-card>
    </v-main>
    <Footer />
  </v-app>
</template>
<script>
// @ is an alias to /src
import PageHeader from "@/components/Header";
import Footer from "@/components/Footer";
import userService from "../services/Users";
import BuyCredits from "@/components/BuyCredits";
import AddCamper from "@/components/AddCamper";
import AddCard from "@/components/AddCard";
import AreYouSure from "../components/AreYouSure";
import loading from "../components/Loading";
import moment from "moment";
export default {
  name: "MyAccount",
  components: {
    BuyCredits,
    AddCamper,
    AddCard,
    AreYouSure,
    PageHeader,
    Footer,
    loading
  },
  data() {
    return {
      userInfo: null,
      userCards: null,
      emailCredits: 0,
      firstname: null,
      lastname: null,
      email: null,
      currentpassword: null,
      newpassword: null,
      showPassword: false,
      showNewPassword: false,
      id: null,
      usercampers: null,
      usercards: null,
      newsletter: 0,
      loading1: false,
      valid: true,
      error: null,
      loading2: true
    };
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    async refresh() {
      this.userInfo = (await userService.user()).data;
      this.firstname = this.userInfo.user[0].FirstName;
      this.lastname = this.userInfo.user[0].LastName;
      this.newsletter = this.userInfo.user[0].Newsletter;
      this.email = this.userInfo.user[0].Email;
      this.id = this.userInfo.user[0].Id;
      this.emailCredits = this.userInfo.user[0].EmailCredits || 0;
      this.usercampers = this.userInfo.usercampers;
      this.usercards = this.userInfo.usercards;

      this.loading2 = false;
    },
    async deleteCamper(Id) {
        await userService.deleteCamper(Id);
        this.userInfo = (await userService.user()).data;
        this.usercampers = this.userInfo.usercampers;
        this.$refs.areyousure.areYouSurePopUp = false;
        this.$refs.areyousure.loading1 = false;
    },
    async addCard() {
      this.$refs.addcard.addCardDialog();
      window.addEventListener("message", this.newMessage);
    },

    async buyCredits() {
      this.$refs.buycredits.buyCreditsDialog = true;
      window.addEventListener("message", this.newMessage);
    },

    async addCameper() {
      this.$refs.addcamper.addCameperDialog = true;
      window.addEventListener("message", this.newMessage);
    },
    async areyousure(type, Id) {
      this.$refs.areyousure.openpopup(type, Id);
      window.addEventListener("message", this.newMessage);
    },
    async deleteCard(Id) {
      try {
        await userService.deleteCard(Id);
        this.userInfo = (await userService.user()).data;
        this.usercards = this.userInfo.usercards;

        this.$refs.areyousure.loading1 = false;
      } catch (err) {
        alert(err);
      }
    },
    async newMessage(message) {
      window.removeEventListener("message", message);
      if (message.data.includes("Delete Card Confrimed") == true) {
        const Id = event.data.split("?Id=", 2)[1];
        await this.deleteCard(Id);
      }
      if (message.data.includes("Delete Camper Confrimed") == true) {
        const Id = event.data.split("?Id=", 2)[1];
        await this.deleteCamper(Id);
      }
      if (message.data.includes("refresh") == true) {
        await this.refresh();
      }
      if (message.data.includes("addCardDeclined") === true) {
        alert('Card Declined')
      }
      if (message.data.includes("addCardError") === true) {
        alert('Error Adding Card Please Try Again')
      }
    },
    async save() {
      this.loading1 = true;
      if (this.$refs.form.validate())
        if (this.newpassword == null || this.$refs.form2.validate()) {
          try {
            this.error = null
            const response = await userService.updateUser({
              FirstName: this.firstname,
              LastName: this.lastname,
              Email: this.email,
              CurrentPassword: this.currentpassword,
              NewPassword: this.newpassword,
              NewsLetter: this.newsletter
            });
            if(this.newpassword){
              this.$store.dispatch("login", response.data);
            }
            this.loading1 = false;
          } catch (err) {

            this.error = err.response.data.error;
            this.loading1 = false;
          }
        }
    }
  }
};
</script>
<style scoped>
/*to be removed */
.mainBody {
  padding-right: 10%;
  padding-left: 10%;
  padding-top: 3%;
  background-color: #f1eff2;
  /*background: linear-gradient(to bottom right, #1a86ce, #4b5461);*/
}
.accountinfo {
  padding: 2%;

}
.rounded-corner {
  border-radius: 20px;
}

.cards .theme--light.v-sheet {
  /*background-color: #f1eff2;*/
  /*background-size: 200% 200%;*/
  /*align-items: "center";*/
}
.cc {
  font-family: monospace;
}

.cards{
  /*background-image: url("/images/card_background.jpg");*/
  background-image: url(../../public/images/card_background.jpg);
}
/* .theme--light.v-list {
    background: #FFA500;
    color: rgba(0, 0, 0, 0.87);
} /*/
.theme--light.v-card {
   background-color: #ffffff00;
}

.center {
  margin: auto;
}
</style>

