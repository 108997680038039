import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"areyousure"}},[_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.areYouSurePopUp),callback:function ($$v) {_vm.areYouSurePopUp=$$v},expression:"areYouSurePopUp"}},[_c(VCard,[_c('h2',{staticClass:"text-center"},[_vm._v("Are You Sure You Want To "+_vm._s(this.type))]),_c(VCardActions,[_c(VBtn,{attrs:{"rounded":"","color":"primary"},on:{"click":_vm.closepopup}},[_vm._v("Cancel")]),_c(VSpacer),_c(VBtn,{staticClass:"white",attrs:{"rounded":"","loading":_vm.loading1,"disabled":_vm.loading1,"color":"primary"},on:{"click":_vm.confrimed},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',{staticClass:"custom-loader"},[_c(VIcon,{attrs:{"light":""}},[_vm._v("mdi-refresh")])],1)]},proxy:true}])},[_vm._v(" Yes ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }