<template>
  <div id="credits">
    <v-dialog v-model="buyCreditsDialog" max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">Buy Credit</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="creditQuantity"
                    label="Amount Of Credits"
                    :rules="[v => Number.isFinite(Number(v)), v => !!v  || 'Numbers only']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <h3>{{ total || "Total is $0.00"}}</h3>
                </v-col>
                <v-col cols="12" v-if="usercards && usercards.length > 0">
                  Payment Type*
                  <v-radio-group
                    prepend-icon="mdi-credit-card"
                    v-model="selectedCard"
                    :rules="[v => !!v || 'Item is required']"
                  >
                    <v-radio
                      v-for="usercard in usercards"
                      :key="usercard.Id"
                      :label="usercard.MaskedCardNumber"
                      :value="usercard.Id"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col>
                  <br />
                  <v-btn depressed @click="addCard" class="text-center">
                    <v-icon>mdi-credit-card-plus-outline</v-icon>
                    <div v-if="usercards && usercards.length > 0">Add New Credit Card</div>
                    <div v-else>Please Add Card To Proceed</div>
                  </v-btn>
                  <add-card ref="addcard" />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <div class="text-center" v-if="this.error != null">
          <h2 class="error">CARD DECLINED Error {{error}}</h2>
        </div>
        <v-card-actions>
          <v-btn color="primary" rounded @click="buyCreditsDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            class="white"
            :loading="loading1"
            @click="buy"
            :disabled="loading1 ||!valid"
            color="primary"
          >
            Buy
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-refresh</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import AddCard from "@/components/AddCard";
import usersService from "../services/Users";

export default {
  name: "AddCredits",
  props: ["usercards"],
  components: { AddCard },
  data() {
    return {
      buyCreditsDialog: false,
      valid: true,
      creditQuantity: null,
      loading1: false,
      selectedCard: null,
      error: null
    };
  },
  computed: {
    total() {
      const totalCost = this.creditQuantity * this.creditPrice;
      if (totalCost > 0) return "Total is $" + totalCost.toFixed(2);
    },
    creditPrice(){
      return this.$store.state.config.configurations.mailCreditPrice
    }
  },
  async mounted() {
    if(!this.creditPrice){
      await this.$store.dispatch("config/config")
    }

  },
  methods: {
    async buy() {
      if (this.$refs.form.validate())
        try {
          this.loading1 = true;
          const response = await usersService.addCredit({
            CreditQuantity: this.creditQuantity,
            CreditPrice: this.creditPrice,
            CardId: this.selectedCard
          });
          this.loading1 = false;
          this.$refs.form.reset();
          const message = "refresh";
          window.parent.postMessage(message, "*");
          this.buyCreditsDialog = false;
          this.loading1 = false;
        } catch (error) {
          this.loading1 = false;
          this.error = error.response.data.error;
        }
    },
    async addCard() {
      this.$refs.addcard.addCardDialog();
      window.addEventListener("message", this.newMessage);
    }
  }
};
</script>
<style scoped>
</style>