<template>
  <div id="campers">
    <v-dialog v-model="addCameperDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Add Camper</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                      label="First Name*"
                      v-model="selectedfirstName"
                      :rules="[v => !!v || 'Item is required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                      label="Last Name*"
                      v-model="selectedlastName"
                      :rules="[v => !!v || 'Item is required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  Gender*
                  <v-radio-group v-model="selectedGender" :rules="[v => !!v || 'Item is required']">
                    <v-radio label="Male" value="1"></v-radio>
                    <v-radio label="Female" value="2"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                      chips
                      v-model="selectedbranchId"
                      :items="branches"
                      item-text="name"
                      label="Branch*"
                      item-value="id"
                      required
                      :rules="[v => !!v || 'Item is required']"
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-btn rounded color="primary" @click="cancel">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn
              rounded
              class="white"
              :loading="loading1"
              @click="addCamper"
              :disabled="loading1 ||!valid"
              color="primary"
          >
            submit
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-refresh</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// @ is an alias to /src
import usersService from "../services/Users";
export default {
  name: "AddCamper",
  props: [],
  components: {},
  data() {
    return {
      addCameperDialog: false,
      loading1: false,
      selectedfirstName: null,
      selectedlastName: null,
      selectedGender: null,
      selectedbranchId: null,
      valid: true,
    };
  },
  async mounted() {
    if (!this.$store.state.config.branches)
      await this.$store.dispatch("config/config")
  },
  computed: {
    branches() {
      return this.$store.state.config.branches
    }
  },
  methods: {
    cancel() {
      this.$refs.form.reset();
      this.addCameperDialog = false;
    },
    async addCamper() {
      if (this.$refs.form.validate())
        try {
          this.loading1 = true;
          await usersService.addCamper({
            FirstName: this.selectedfirstName,
            LastName: this.selectedlastName,
            BranchId: this.selectedbranchId,
            Gender: this.selectedGender

          });
          this.loading1 = false;
          this.addCameperDialog = false;
          this.$refs.form.reset();
          const message = "refresh";
          window.parent.postMessage(message, "*");
        } catch (error) {
          this.loading1 = false;

          alert(error);
        }
    }
  }
};
</script>
<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>