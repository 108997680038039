<template>
  <div>
    <v-dialog v-model="addcarddialog" max-width="600px" >
      <v-card  style="height:39em">
        <v-icon large @click="addcarddialog = !addcarddialog">mdi-close-circle-outline</v-icon>
        <h2 class="text-center">Add New Card</h2>
        <v-spacer></v-spacer>
        <iframe
            id="addCardFrame"
            v-if="this.iFrameUrl"
            :src="this.iFrameUrl"
            style=" height:100%; width: 100%;"
        >Your browser doesn't support iframes please try another browser
        </iframe>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

export default {
  name: "AddCard",
  components: {},
  props: ["id"],
  data() {
    return {
      addcarddialog: false,
      iFrameUrl: null
    };
  },
  async mounted() {
  },
  watch:{
    addcarddialog(){
      if(!this.addcarddialog){
        this.iFrameUrl = null;
      }

    }
  },
  methods: {
    addCardDialog() {
      this.iFrameUrl = `${window.location.origin}/api/v1/users/payment?authorization=${this.$store.state.authentication.token2}`
      this.addcarddialog = true;
      window.addEventListener("message", event => {
        this.iFrameUrl = null;
        this.addcarddialog = false;
      });
    }
  }
};
</script>
<style scoped>
</style>